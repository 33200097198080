import { assert } from "@faro-lotv/foundation";
import {
  GUID,
  IElement,
  IElementType,
  IElementTypeHint,
  IPose,
  IQuat,
  IRefCoordSystemTransform,
  IVec3,
} from "@faro-lotv/ielement-types";
import { DataSetAreaInfo } from "@faro-lotv/service-wires";
import { IElementsRecord } from "../i-elements-slice";
import {
  Matrix4,
  Matrix4Tuple,
  Quaternion,
  Vector3,
  Vector3Tuple,
  Vector4Tuple,
} from "./math";
import {
  convertIElementToThreeRotation,
  decomposeMatrix,
  isPoseLeftHanded,
} from "./transform-conversion";

export const LEFT_TO_RIGHT = Object.freeze(new Matrix4().makeScale(1, 1, -1));

type ReferenceSystemTransform = {
  /** The matrix to multiply to the right of the pose */
  rightMatrix: Matrix4;
  /** The matrix to multiply to the left of the pose */
  leftMatrix: Matrix4;
};

/** IElement world transformation data cached in this slice */
export interface CachedWorldTransform {
  /**
   * World Matrix of an IElement, computed by reading the local poses of the IElement and its parents
   * from the project API and accounting also for the refCoordSystemMatrix prop. Since the user is able
   * to specify any rotation and scale in the local pose and refCoordSystemMatrix of a given iElement,
   * this matrix can be either left- or right-handed.
   */
  worldMatrixMixedHd: Matrix4Tuple;

  /**
   * World matrix of an IElement, expressed in the Viewer's CS
   * which is right-handed.
   */
  worldMatrix: Matrix4Tuple;

  /** World 3d position of an IElement, in the Viewer CS*/
  position: Vector3Tuple;

  /** World quaternion of an IElement, in the Viewer CS */
  quaternion: Vector4Tuple;

  /** World scale of an IElement, in the Viewer CS */
  scale: Vector3Tuple;
}

/** Default identity transform used when a valid transform is not available */
export const DEFAULT_TRANSFORM: CachedWorldTransform = {
  worldMatrixMixedHd: new Matrix4().toArray(),
  worldMatrix: new Matrix4().toArray(),
  position: [0, 0, 0],
  quaternion: [0, 0, 0, 1],
  scale: [1, 1, 1],
};

/** A cache for the world transformations of IElements */
export type WorldTransformCache = Record<GUID, CachedWorldTransform>;

/**
 * @param pose The pose to convert to a transformation matrix.
 *  This must be given in Y-up left-handed coordinate system, as defined by the Project API.
 * @returns the corresponding transformation matrix, in Y-up right-handed coordinate system.
 */
export function poseToMatrix4(pose: IPose | null | undefined): Matrix4 {
  if (!pose) return new Matrix4();
  return new Matrix4().compose(
    new Vector3(pose.pos?.x ?? 0, pose.pos?.y ?? 0, pose.pos?.z ?? 0),
    new Quaternion(
      pose.rot?.x ?? 0,
      pose.rot?.y ?? 0,
      pose.rot?.z ?? 0,
      pose.rot?.w ?? 1,
    ),
    new Vector3(pose.scale?.x ?? 1, pose.scale?.y ?? 1, pose.scale?.z ?? 1),
  );
}

/**
 * Function to convert a Matrix in our cached world transform
 *
 * @param matrix The new world transform matrix
 * @returns the matrix decomposed in multiple arrays
 */
export function computeCachedWorldTransform(
  matrix: Matrix4,
): CachedWorldTransform {
  const matrixRhd = matrix.clone();
  if (isPoseLeftHanded(matrixRhd)) {
    matrixRhd.multiply(LEFT_TO_RIGHT);
  }

  const position = new Vector3();
  const quaternion = new Quaternion();
  const scale = new Vector3();
  matrixRhd.decompose(position, quaternion, scale);

  return {
    worldMatrixMixedHd: matrix.toArray(),
    worldMatrix: matrixRhd.toArray(),
    position: [position.x, position.y, position.z],
    quaternion: [quaternion.x, quaternion.y, quaternion.z, quaternion.w],
    scale: [scale.x, scale.y, scale.z],
  };
}

/**
 * Compute the actual world matrix by taking into account the world-referred parameters
 *
 * @param transform input is the element's world transformation as if there was no isWorldXXX flags;
 *  output will be the actual world transformation of the element
 * @param elementPose The original pose of the element for which we are computing the world matrix
 * @returns The actual world matrix
 */
function accountForWorldPoseFlags(
  transform: Matrix4,
  elementPose: IPose | null | undefined,
): Matrix4 {
  // Override transform parameters that are world-referred
  if (
    !!elementPose?.isWorldRot ||
    !!elementPose?.isWorldScale ||
    !!elementPose?.isWorldPose
  ) {
    // Remove the left-to-right conversion applied by the root
    transform.premultiply(LEFT_TO_RIGHT);

    const { position, quaternion, scale } = decomposeMatrix(transform, "z");

    // If the rotation is world-referred, override the one in the transform
    if (
      !!(elementPose.isWorldRot && elementPose.rot) ||
      elementPose.isWorldPose
    ) {
      quaternion.fromArray(
        convertIElementToThreeRotation(elementPose.rot, false),
      );
    }

    // If the scale is world-referred, override the one in the transform
    if (
      !!(elementPose.isWorldScale && !!elementPose.scale) ||
      elementPose.isWorldPose
    ) {
      scale.set(
        elementPose.scale?.x ?? 1,
        elementPose.scale?.y ?? 1,
        elementPose.scale?.z ?? 1,
      );
    }

    if (elementPose.isWorldPose) {
      position.set(
        elementPose.pos?.x ?? 0,
        elementPose.pos?.y ?? 0,
        elementPose.pos?.z ?? 0,
      );
    }

    transform.compose(position, quaternion, scale);

    // Reapply the left-to-right conversion to the root
    transform.premultiply(LEFT_TO_RIGHT);
  }

  return transform;
}

/**
 * Check if an element is inside a CaptureTree
 *
 * @param element The element to check
 * @param iElements The map with all the projects IElements
 * @returns true if the element is inside a CaptureTree
 */
export function isInsideCaptureTree(
  element: IElementWithPose,
  iElements: Record<GUID, IElementWithPose | undefined>,
): boolean {
  const parent = element.parentId ? iElements[element.parentId] : undefined;
  return (
    element.typeHint === IElementTypeHint.captureTree ||
    (!!parent && isInsideCaptureTree(parent, iElements))
  );
}

/**
 * Update a WorldTransformCache for a sub-tree of a project
 *
 * @param iElements The map with all the projects IElements
 * @param cache The cache to update, used to query parent elements if needed
 * @param areaDataSets The mapping between areas and their datasets in the capture tree
 * @param rootId The root of the sub-tree to recompute, tha parent matrix should be in the cache already, default to root
 */
export function updateWorldTransformCacheSubTree(
  iElements: IElementsRecord,
  cache: WorldTransformCache,
  areaDataSets: Record<GUID, DataSetAreaInfo[] | undefined>,
  rootId?: GUID,
): void {
  // Ensure we have a valid project
  if (Object.keys(iElements).length === 0) {
    throw new Error("Can't update world transformation from an empty project");
  }
  rootId = rootId ?? Object.values(iElements)[0]?.rootId;
  assert(rootId, "Can't update world transformation without a root element");

  // Access the root element from which invalidate and update the cache
  const iElement = iElements[rootId];

  if (!iElement) {
    throw new Error(
      `Requested iElement with id ${rootId} is not in the project`,
    );
  }

  // The initial transform is the parent transform if we're not starting from the root
  const initialTransform = new Matrix4();
  const parent = iElement.parentId ? iElements[iElement.parentId] : undefined;
  if (parent) {
    const parentTransform = cache[parent.id];

    // parentTransform might be undefined in case of `parent.id` being invalid
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!parentTransform) {
      throw new Error(
        `Requested to re-evaluate world transform for element ${rootId} but the parent is not in cache`,
      );
    }
    initialTransform.multiply(
      new Matrix4().fromArray(parentTransform.worldMatrixMixedHd),
    );
  }

  // Recursively compute the cumulative transform for each element and update the cache
  const recursiveUpdate = (
    element: typeof iElement,
    parentTransform: Matrix4,
    elementInsideCaptureTree: boolean,
  ): void => {
    const insideCaptureTree =
      elementInsideCaptureTree ||
      element.typeHint === IElementTypeHint.captureTree;

    let { pose } = element;

    // Check if the element is a data-set in an area and set its world position as the position inside the area
    // TODO: Update this logic to introduce multi-relation between datasets and areas (https://faro01.atlassian.net/browse/SWEB-4470)
    const areaData = findDataSetAreaAndPose(
      element.id,
      areaDataSets,
      iElements,
    );
    if (areaData) {
      // Poses from alignment edges are always interpreted as left-handed
      parentTransform.fromArray(cache[areaData[0]].worldMatrixMixedHd);
      pose = areaData[1];
    }

    const poseMatrix = computeLocalPoseMatrix(pose, element, insideCaptureTree);
    const transform = parentTransform.multiply(poseMatrix);
    const worldTransform = computeCachedWorldTransform(
      accountForWorldPoseFlags(transform, pose),
    );

    cache[element.id] = worldTransform;
    for (const id of element.childrenIds ?? []) {
      // Recurse only if the child is loaded in the slice
      const child = iElements[id];

      // iElements might be undefined in case of `id` being invalid
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (child) {
        // As multiple children will want to compose on this node transform we need a clone for each child
        recursiveUpdate(child, transform.clone(), insideCaptureTree);
      }
    }
  };

  // Start to recurse from the passed iElement
  recursiveUpdate(
    iElement,
    initialTransform,
    isInsideCaptureTree(iElement, iElements),
  );
}

/**
 * @param dataSetId id of the dataset
 * @param areaDataSets the mapping between areas and their datasets
 * @param elements of the projects
 * @returns the id of the area containing this dataset and the local pose of the dataset in the area if available
 *
 * Will ignore datasets from the bi-tree
 */
function findDataSetAreaAndPose(
  dataSetId: GUID,
  areaDataSets: Record<GUID, DataSetAreaInfo[] | undefined>,
  elements: Record<GUID, IElementWithPose | undefined>,
): [GUID, IPose] | undefined {
  for (const [areaId, dataSets] of Object.entries(areaDataSets)) {
    const dataSet = dataSets?.find(
      (dataSet) => dataSet.elementId === dataSetId,
    );
    if (!dataSet) continue;

    const dataSetElement = elements[dataSet.elementId];
    const parentElement = dataSetElement?.parentId
      ? elements[dataSetElement?.parentId]
      : undefined;
    if (
      dataSetElement &&
      parentElement &&
      !(
        parentElement.type === IElementType.section &&
        parentElement.typeHint === IElementTypeHint.dataSession
      )
    ) {
      return [
        areaId,
        {
          gps: null,
          isWorldRot: false,
          ...dataSet.pose,
        },
      ];
    }
  }
}

/** Transforms to overwrite the persisted values */
export type TransformOverrides = {
  /**
   * A map from element ID to the local pose to use for it,
   * instead of the persisted pose from the Project API.
   *
   * These positions must be defined the same way as in the Project API,
   * i.e. in Y-up left-handed reference system.
   */
  local: Record<GUID, IPose | undefined>;

  /**
   * A map from element ID to the global pose to use for it,
   * instead of the calculated pose from the Project API poses.
   *
   * These positions must be defined the same way as in the cache,
   * i.e. in Y-up right-handed reference system.
   *
   * If both local and global poses are overwritten, the global position is returned.
   */
  global: Record<GUID, CachedWorldTransform | undefined>;
};

/**
 * Compute an iElement world transform using a support cache
 *
 * If the transform is already in the cache returns it
 * If not compute the element transform using the cache for the parents if needed
 *
 * @param iElements All the available iElements in the project
 * @param iElement The IElement we want the world cached transform
 * @param cache The support cache
 * @param areaDataSets The mapping between areas and their datasets in the capture tree
 * @param transformOverrides Transforms to overwrite the persisted values with during the calculation
 * @returns The computed element world cached transform
 */
export function getIElementWorldTransform(
  iElements: Record<GUID, IElementWithPose | undefined>,
  iElement: IElementWithPose,
  cache: WorldTransformCache,
  areaDataSets: Record<GUID, DataSetAreaInfo[] | undefined>,
  transformOverrides?: TransformOverrides,
): CachedWorldTransform {
  // Return directly if overridden or cached
  const worldTransform: CachedWorldTransform | undefined =
    transformOverrides?.global[iElement.id] ?? cache[iElement.id];

  if (worldTransform) {
    return worldTransform;
  }

  // Build the new transform
  const transform = new Matrix4();

  // Get parent matrix, will compute recursively all the parents are needed
  if (iElement.parentId) {
    const parent = iElements[iElement.parentId];

    if (parent) {
      transform.fromArray(
        getIElementWorldTransform(
          iElements,
          parent,
          cache,
          areaDataSets,
          transformOverrides,
        ).worldMatrixMixedHd,
      );
    }
  }

  // Compose local transform with the parents
  let localPose: IPose | null | undefined =
    transformOverrides?.local[iElement.id];

  const insideCaptureTree = isInsideCaptureTree(iElement, iElements);

  if (!localPose) {
    localPose = iElement.pose;

    // Check if the element is a data-set in an area and set its world position as the position inside the area
    // TODO: Update this logic to introduce multi-relation between datasets and areas (https://faro01.atlassian.net/browse/SWEB-4470)
    const areaData = findDataSetAreaAndPose(
      iElement.id,
      areaDataSets,
      iElements,
    );
    if (areaData) {
      const areaId = areaData[0];
      let edge = cache[areaId];
      if (!edge) {
        const area = iElements[areaId];
        assert(area, "Area IElement not downloaded yet");
        edge = getIElementWorldTransform(
          iElements,
          area,
          cache,
          areaDataSets,
          transformOverrides,
        );
      }

      // Poses from alignment edges are always interpreted as left-handed
      transform.fromArray(edge.worldMatrixMixedHd);
      localPose = areaData[1];
    }
  }

  const poseMatrix = computeLocalPoseMatrix(
    localPose,
    iElement,
    insideCaptureTree,
  );
  transform.multiply(poseMatrix);

  // Consider isWorldXXX flags in the computed transform
  return computeCachedWorldTransform(
    accountForWorldPoseFlags(transform, localPose),
  );
}

/**
 *
 * @param pose Input pose as read from the Project API.
 * @param iElement IElement whose pose must be computed
 * @param insideCaptureTree Whether the iElement is inside the capture tree or not
 * @returns The iElement's local pose, expressed as a 4x4 matrix in the Viewer's CS
 */
function computeLocalPoseMatrix(
  pose: IPose | null | undefined,
  iElement: IElementWithPose,
  insideCaptureTree: boolean,
): Matrix4 {
  const refCoordSystemTransform = computeReferenceSystemTransform(
    iElement,
    insideCaptureTree,
  );
  const poseMatrix = poseToMatrix4(pose);

  poseMatrix.premultiply(refCoordSystemTransform.leftMatrix);
  poseMatrix.multiply(refCoordSystemTransform.rightMatrix);
  return poseMatrix;
}

/**
 * @returns The reference coordinate system matrices for a specific iElement
 * @param iElement The input IElement.
 * @param insideCaptureTree Whether the element is inside the capture tree or not.
 */
export function computeReferenceSystemTransform(
  iElement: IElementWithPose,
  insideCaptureTree: boolean,
): ReferenceSystemTransform {
  return convertReferenceSystemProperties(
    computeReferenceSystemProperties(iElement, insideCaptureTree),
  );
}

/**
 * @returns The matrices obtained by combining the properties of an IRefCoordSystemTransform
 * @param refCoordSystemTransform The input IRefCoordSystemTransform
 */
export function convertReferenceSystemProperties(
  refCoordSystemTransform: IRefCoordSystemTransform | null,
): ReferenceSystemTransform {
  const pos = new Vector3(
    refCoordSystemTransform?.pos?.x ?? 0,
    refCoordSystemTransform?.pos?.y ?? 0,
    refCoordSystemTransform?.pos?.z ?? 0,
  );
  const rot = new Quaternion(
    refCoordSystemTransform?.rot?.x ?? 0,
    refCoordSystemTransform?.rot?.y ?? 0,
    refCoordSystemTransform?.rot?.z ?? 0,
    refCoordSystemTransform?.rot?.w ?? 1,
  );
  const scale = new Vector3(
    refCoordSystemTransform?.scale?.x ?? 1,
    refCoordSystemTransform?.scale?.y ?? 1,
    refCoordSystemTransform?.scale?.z ?? 1,
  );
  const leftMatrix = new Matrix4().makeScale(
    1,
    1,
    refCoordSystemTransform?.preScaleZ ?? 1,
  );
  return {
    rightMatrix: new Matrix4().compose(pos, rot, scale),
    leftMatrix,
  };
}

/**
 * @returns The reference coordinate system properties for a specific iElement
 * @param iElement The input IElement.
 * @param insideCaptureTree Whether the element is inside the capture tree or not.
 */
export function computeReferenceSystemProperties(
  iElement: Omit<IElementWithPose, "id">,
  insideCaptureTree: boolean,
): IRefCoordSystemTransform | null {
  if (iElement.pose?.refCoordSystemMatrix) {
    return iElement.pose?.refCoordSystemMatrix;
  }

  // Returning a specific reference system matrix according to the iElement type
  switch (iElement.type) {
    case IElementType.section: {
      // Since the PCSG adds Focus Scan with a right handed pose, but all its children and siblings are left handed
      // we add the flipping to the focus dataset, so that the sub-tree can be entirely expressed with
      // a right handed system. To compensate this flip, we also need to make the structuredE57 right handed
      // so we pre-flip and post-flip its pose
      // (see https://faroinc.sharepoint.com/:p:/r/sites/SWEB/Resources/Documents/SWEB-4941-capture-tree-handness.pptx?d=w4260bf7d332a4c22bfe422b97d7fc081&csf=1&web=1&e=jmm0P6&nav=eyJzSWQiOjI1NywiY0lkIjoyNDM3MDIyMDYxfQ)
      if (insideCaptureTree) {
        if (iElement.typeHint === IElementTypeHint.dataSetFocus) {
          return {
            pos: null,
            rot: null,
            scale: { x: 1, y: 1, z: -1 },
            preScaleZ: null,
          };
        }
        if (iElement.typeHint === IElementTypeHint.structuredE57) {
          return {
            pos: null,
            rot: null,
            scale: { x: 1, y: 1, z: -1 },
            preScaleZ: -1,
          };
        }
      }
      break;
    }
    case IElementType.projectRoot:
      return { pos: null, rot: null, scale: null, preScaleZ: -1 };
    case IElementType.img360: {
      // Hack needed because we want to represent panos as Z-Up spheres with the center going toward -X,
      // but the current pano in the data models by default expect a geometry that is Y-up with the center going toward +X
      return {
        pos: null,
        rot: { x: 0, y: -Math.SQRT1_2, z: Math.SQRT1_2, w: 0 },
        scale: { x: 1, y: 1, z: -1 },
        preScaleZ: insideCaptureTree ? -1 : null,
      };
    }
    // Img sheets are represented by quads in the XY plane. To view them in our
    // Y-up ref system, we rotate them 90 degrees around the X axis.
    case IElementType.imgSheet: {
      return {
        pos: null,
        rot: {
          x: Math.SQRT1_2,
          y: 0,
          z: 0,
          w: Math.SQRT1_2,
        },
        scale: { x: 1, y: 1, z: -1 },
        preScaleZ: null,
      };
    }
    case IElementType.imgSheetTiled: {
      // Tiled image sheet in the ProjectAPI have the top left corner at the origin
      // We apply an offset to in order to move at the origin the bottom left corner,
      // like simple image sheets.

      // The geometry is based on a 100x100 square, hence why we offset by 100
      const Y_OFFSET = -100;
      return {
        pos: { x: 0, y: 0, z: Y_OFFSET },
        rot: {
          x: Math.SQRT1_2,
          y: 0,
          z: 0,
          w: Math.SQRT1_2,
        },
        scale: { x: 1, y: 1, z: -1 },
        preScaleZ: insideCaptureTree ? -1 : null,
      };
    }
    case IElementType.model3d: {
      if (iElement.typeHint === IElementTypeHint.node) {
        // If we enter here, we are handling a markup annotation. Markup annotations
        // should be rotate 180 degrees around their local vertical, otherwise they
        // appear flipped left-to-right.
        return {
          pos: null,
          rot: { x: 0, y: 1, z: 0, w: 0 },
          scale: { x: 1, y: 1, z: -1 },
          preScaleZ: insideCaptureTree ? -1 : null,
        };
      }
      return {
        pos: null,
        rot: null,
        scale: { x: 1, y: 1, z: -1 },
        preScaleZ: null,
      };
    }
    case IElementType.pointCloudCpe:
    case IElementType.pointCloudE57:
    case IElementType.pointCloudGeoSlam:
    case IElementType.pointCloudLaz:
    case IElementType.pointCloudStream:
      return {
        pos: null,
        rot: null,
        scale: { x: 1, y: 1, z: -1 },
        preScaleZ: insideCaptureTree ? -1 : null,
      };
    case IElementType.model3dStream:
      return {
        pos: null,
        rot: null,
        scale: { x: 1, y: 1, z: -1 },
        preScaleZ: null,
      };
    case IElementType.group: {
      if (insideCaptureTree && iElement.typeHint === IElementTypeHint.nodes) {
        return {
          pos: null,
          rot: null,
          scale: { x: 1, y: 1, z: -1 },
          preScaleZ: -1,
        };
      }
      break;
    }
    case IElementType.measurePolygon:
    case IElementType.markupPolygon: {
      if (insideCaptureTree) {
        return {
          pos: null,
          rot: null,
          scale: null,
          preScaleZ: -1,
        };
      }
      break;
    }
  }
  return null;
}

/** Raw pose data of an iElement, as read from the Project API */
export type ProjectApiPose = {
  pos: IVec3;
  rot: IQuat;
  scale: IVec3;
  refCoordSystemMatrix: IRefCoordSystemTransform | null;
};

export type IElementWithPose = Pick<
  IElement,
  "id" | "parentId" | "pose" | "type" | "typeHint"
>;

const TEMP_MATRIX4 = new Matrix4();

/**
 * Given a right-handed local pose computed in the Viewer reference system,
 * extracts all the properties that should be set in the pose of the iElement
 * when updating the pose in the ProjectAPI, by extracting the contributions of
 * the refCoordSystemMatrix
 *
 * @param element The element whose local pose should be computed
 * @param localPose The local pose of the element, containing the refCoordSystemMatrix contribution
 * @param isInsideCaptureTree A flag specifying if the iElement is in the CaptureTree subtree
 * @param elementWorldTransform The cached world transform of the element
 * @param parentWorldTransform The chaced world transform of the parent element
 * @returns The pose properties of the iElement
 */
export function extractProjectApiLocalPose(
  element: IElementWithPose,
  localPose: Matrix4,
  isInsideCaptureTree: boolean,
  elementWorldTransform: CachedWorldTransform,
  parentWorldTransform: CachedWorldTransform,
): ProjectApiPose {
  const refCoordSystemTransfromProperties = computeReferenceSystemProperties(
    element,
    isInsideCaptureTree,
  );
  const { leftMatrix, rightMatrix } = convertReferenceSystemProperties(
    refCoordSystemTransfromProperties,
  );

  const matrix = localPose.clone();

  // The world matrix of the two iElements (parent and element) could be left handed. If that's the case, it means that
  // when the cache computed the right-handed world matrix, it applied a post-mirroring to the matrix.
  // We need to compensate that when extracting the pose to send to the Project API
  if (
    isPoseLeftHanded(
      TEMP_MATRIX4.fromArray(parentWorldTransform.worldMatrixMixedHd),
    )
  ) {
    matrix.premultiply(LEFT_TO_RIGHT);
  }
  if (
    isPoseLeftHanded(
      TEMP_MATRIX4.fromArray(elementWorldTransform.worldMatrixMixedHd),
    )
  ) {
    matrix.multiply(LEFT_TO_RIGHT);
  }

  matrix
    .premultiply(
      leftMatrix,
      // .invert() The correct operation here would be to invert leftMatrix,
      // however, we can avoid that because by design we know that leftMatrix is always involuted.
    )
    .multiply(rightMatrix.invert());

  const pos = new Vector3();
  const rot = new Quaternion();
  const scale = new Vector3();
  matrix.decompose(pos, rot, scale);

  assert(
    scale.x > 0 && scale.y > 0 && scale.z > 0,
    "Invalid iElement local pose",
  );

  return {
    pos: { x: pos.x, y: pos.y, z: pos.z },
    rot: { x: rot.x, y: rot.y, z: rot.z, w: rot.w },
    scale: { x: scale.x, y: scale.y, z: scale.z },
    refCoordSystemMatrix: refCoordSystemTransfromProperties,
  };
}

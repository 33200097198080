import { assert } from "@faro-lotv/foundation";
import { BufferAttribute, BufferGeometry, Color, Plane, Points, Vector3 } from "three";
import { ColormapPointsMaterial } from "../Materials/ColormapPointsMaterial";

/** Colormap key value and color pair */
export type ColormapKey = {
	// Scale value within range of [0, 1]
	value: number;
	// Color associated with the value
	color: Color;
};

/**
 * Colormap defined as a list of colormap keys.
 * A proper colormap should be defined as:
 *  - At least 2 keys
 *  - The first key value should be 0, and last one should be 1
 *  - All key values should be in ascending order
 */
export type Colormap = ColormapKey[];

// Check if user provided colormap are defined properly
function isColormapValid(colormap: Colormap): boolean {
	if (colormap.length < 2) return false;
	if (colormap[0].value !== 0) return false;
	if (colormap[colormap.length - 1].value !== 1) return false;

	for (let i = 0; i < colormap.length - 1; i++) {
		if (colormap[i].value > colormap[i + 1].value) return false;
	}
	return true;
}

// check if two colormaps are equal
function equals(colormap1: Colormap, colormap2: Colormap): boolean {
	if (colormap1.length !== colormap2.length) return false;
	for (let i = 0; i < colormap1.length; i++) {
		if (colormap1[i].value !== colormap2[i].value) return false;
		if (!colormap1[i].color.equals(colormap2[i].color)) return false;
	}
	return true;
}

/**
 * Class that renders points using a customizable colormap.
 *
 * For each point, its deviation to a reference plane is used to compute a scale
 * within the deviation range, then this scale is used to find the interpolated
 * color in the colormap.
 */
export class ColormapPoints extends Points<BufferGeometry, ColormapPointsMaterial> {
	#referencePlane = new Plane();
	#colormap: Colormap = [];

	/**
	 * Create colormap points from input data
	 *
	 * @param positions The input points position data, array of [x,y,z,x,y,z...]
	 * @param referencePlane The reference plane
	 * @param colormap The colormap to use
	 */
	constructor(positions: Float32Array, referencePlane: Plane, colormap: Colormap) {
		const geometry = new BufferGeometry();
		geometry.setAttribute("position", new BufferAttribute(positions, 3));
		geometry.computeBoundingBox();
		const material = new ColormapPointsMaterial();
		super(geometry, material);

		this.#setReferencePlane(referencePlane);
		this.#setColormap(colormap);

		// colormap deviation range is initialized with the min and max deviations from the points to
		// the reference plane
		const [minDeviation, maxDeviation] = computeMinMaxDeviation(positions, this.#referencePlane);
		this.material.uniforms.minDeviation.value = minDeviation;
		this.material.uniforms.maxDeviation.value = maxDeviation;
	}

	/** @returns The size of the point */
	get pointSize(): number {
		return this.material.size;
	}

	/**
	 * Set the size of the point
	 *
	 * @param value The size of the point
	 */
	set pointSize(value: number) {
		this.material.size = value;
	}

	/** @returns The maximum deviation value of the colormap */
	get maxDeviation(): number {
		return this.material.uniforms.maxDeviation.value;
	}

	/**
	 * Set the maximum deviation value of the colormap
	 *
	 *  @param value The maximum deviation value of the colormap
	 */
	set maxDeviation(value: number) {
		this.material.uniforms.maxDeviation.value = value;
	}

	/** @returns The minimum deviation value of the colormap */
	get minDeviation(): number {
		return this.material.uniforms.minDeviation.value;
	}

	/**
	 * Set the minimum deviation value of the colormap
	 *
	 * @param value The minimum deviation value of the colormap
	 */
	set minDeviation(value: number) {
		this.material.uniforms.minDeviation.value = value;
	}

	/** @returns The reference plane */
	get referencePlane(): Plane {
		return this.#referencePlane;
	}

	/** @returns The colormap */
	get colormap(): Colormap {
		return this.#colormap;
	}

	/**
	 * Set the colormap to use
	 *
	 * @param colormap The colormap to use
	 */
	set colormap(colormap: Colormap) {
		if (!equals(colormap, this.#colormap)) {
			this.#setColormap(colormap);
		}
	}

	#setReferencePlane(plane: Plane): void {
		this.#referencePlane = plane.clone();
		this.#referencePlane.normalize();
		this.material.uniforms.referencePlane.value.set(
			this.#referencePlane.normal.x,
			this.#referencePlane.normal.y,
			this.#referencePlane.normal.z,
			this.#referencePlane.constant,
		);
	}

	#setColormap(colormap: Colormap): void {
		const colorKeysValid = isColormapValid(colormap);
		if (colorKeysValid) {
			this.#colormap = colormap.slice();
			const nbKeys = this.#colormap.length;
			this.material.defines.NUMBER_OF_COLOR_KEYS = nbKeys;
			const uniformData = new Float32Array(nbKeys * 4);
			for (let i = 0; i < nbKeys; i++) {
				const { value, color } = this.#colormap[i];
				uniformData[i * 4 + 0] = color.r;
				uniformData[i * 4 + 1] = color.g;
				uniformData[i * 4 + 2] = color.b;
				uniformData[i * 4 + 3] = value;
			}
			this.material.uniforms.colorKeys.value = uniformData;
		} else {
			console.warn("Invalid colormap, no color for the points.");
			this.#colormap.length = 0;
			this.material.defines.NUMBER_OF_COLOR_KEYS = 0;
		}
		this.material.needsUpdate = true;
	}
}

/**
 * Compute the minimum and maximum deviation of a set of points to a plane
 *
 * @param points The points to compute the deviation
 * @param plane The reference plane
 * @returns [minDeviation, maxDeviation]
 */
function computeMinMaxDeviation(points: Float32Array, plane: Plane): [number, number] {
	assert(points.length > 0 && points.length % 3 === 0, "Invalid points array");

	const nbPoints = points.length / 3;
	let minDeviation = Infinity;
	let maxDeviation = -Infinity;
	const pt = new Vector3();
	for (let i = 0; i < nbPoints; i++) {
		pt.fromArray(points, i * 3);
		const deviation = plane.distanceToPoint(pt);
		minDeviation = Math.min(minDeviation, deviation);
		maxDeviation = Math.max(maxDeviation, deviation);
	}
	return [minDeviation, maxDeviation];
}

import {
	Box3,
	Camera,
	DataTexture,
	FloatType,
	NearestFilter,
	OrthographicCamera,
	PerspectiveCamera,
	PointsMaterial,
	RGBAFormat,
	Shader,
	ShaderLib,
	UniformsUtils,
	Vector2,
	Vector3,
	WebGLRenderer,
} from "three";
import { LodTreeNode } from "../Lod";
import vert from "../Shaders/AdaptivePoints.vert";
import { makeUniform } from "./Uniforms";

const TEXTURE_SIZE = 2048;

export type AdaptivePointsMaterialParameters = {
	/** The initial minimum size of the points in pixels.*/
	minSize: number;
	/** The initial maximum size of the points in pixels.*/
	maxSize: number;
};

const DEFAULT_PARAMETERS: AdaptivePointsMaterialParameters = {
	minSize: 2,
	maxSize: 50,
};

/** Lod point cloud material that adapts the points size based on the tree structure */
export class AdaptivePointsMaterial extends PointsMaterial {
	vertexShader: string;
	fragmentShader: string;

	// Flag to notify threejs that uniforms were changed in an onBeforeRender call and needs to be updated
	uniformsNeedUpdate = false;
	// Flag required for threejs to properly handle "uniformsNeedUpdate"
	isShaderMaterial = true as const;

	uniforms = ShaderLib.points.uniforms;

	/**
	 * This material leverages the current visible LOD tree cut stored into this data texture.
	 * This texture stores info about the visible LOD nodes in hierarchy order from root to leaves.
	 * For each visible nodes four bytes are stored: the visible children count,
	 * the offset to the closest child, and a value proportional to the logarithmic point density of the node
	 */
	#treeTexture: DataTexture;

	/**
	 * For each node, we also store the bounding box information in a texture, using four floating point values:
	 * the first three describes the bounding box center, while the fourth one specifies the box size.
	 * NOTE: this approach only supports cubic boxes
	 */
	#boxesTexture: DataTexture;

	#visibleNodeTextureOffsets = new Map<number, number>();

	#targetSizeParams = {
		minSize: 2,
		maxSize: 6,
	};

	uniformsGroups = [];

	/** Temporary members allocated once to improve performance */
	#tempMember = {
		invBoxSize: new Vector3(),
		boxMinimum: new Vector3(),
		boxMaximum: new Vector3(),
	};

	/**
	 * @returns true if there are clipping planes defined
	 *
	 * NOTE: Required by threejs to enable clipping if isShaderMaterial = true
	 */
	get clipping(): boolean {
		return !!this.clippingPlanes?.length;
	}

	/**
	 * Construct an instance of AdaptivePointsMaterial
	 *
	 * @param parameters The initial parameters of the material
	 */
	constructor(private parameters: AdaptivePointsMaterialParameters = DEFAULT_PARAMETERS) {
		super();
		this.type = "AdaptivePointsMaterial";
		this.vertexShader = vert;
		this.fragmentShader = ShaderLib.points.fragmentShader;

		/** Init the default parameters */
		this.parameters = { ...DEFAULT_PARAMETERS, ...this.parameters };
		this.#targetSizeParams = { ...this.parameters };

		this.#setUniforms();
		this.setValues({ size: 0.8, sizeAttenuation: true, vertexColors: true });
		this.#treeTexture = new DataTexture(new Uint8Array(4 * TEXTURE_SIZE), TEXTURE_SIZE, 1, RGBAFormat);
		this.#treeTexture.magFilter = NearestFilter;
		this.#boxesTexture = new DataTexture(
			new Float32Array(4 * TEXTURE_SIZE),
			TEXTURE_SIZE,
			1,
			RGBAFormat,
			FloatType,
		);
		this.#boxesTexture.magFilter = NearestFilter;
	}

	/**
	 * Sets the shader's target size parameters. They can be different from the parameters actually used
	 * e.g. to optimize performance when the camera is moving.
	 */
	set targetMinMaxSize(sz: AdaptivePointsMaterialParameters) {
		this.#targetSizeParams = sz;
		this.minSize = sz.minSize;
		this.maxSize = sz.maxSize;
	}

	/** @returns the shader's target size parameters */
	get targetMinMaxSize(): AdaptivePointsMaterialParameters {
		return this.#targetSizeParams;
	}

	/** Resets the shader size parameter to its target values */
	resetToTargetSize(): void {
		this.minSize = this.#targetSizeParams.minSize;
		this.maxSize = this.#targetSizeParams.maxSize;
	}

	/** @returns the point max size, in screen pixels */
	get maxSize(): number {
		return this.parameters.maxSize;
	}

	/** Sets the point max size, in screen pixels */
	set maxSize(value: number) {
		this.parameters.maxSize = value;
		this.uniforms.uMaxSize.value = value;
		this.uniformsNeedUpdate = true;
	}

	/** @returns the point min size, in screen pixels */
	get minSize(): number {
		return this.parameters.minSize;
	}

	/** Sets the point min size, in screen pixels */
	set minSize(value: number) {
		this.parameters.minSize = value;
		this.uniforms.uMinSize.value = value;
		this.uniformsNeedUpdate = true;
	}

	/**
	 * @returns A clone of this material, with the same parameters
	 */
	// This is the proper clone function, but to implement it we need to
	// disable the typechecker and the linter because it's impossible to make
	// typescript happy with the current implementation of clone in ThreeJS.
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	clone(): AdaptivePointsMaterial {
		const cloned = new AdaptivePointsMaterial(this.parameters);
		cloned.copy(this);
		return cloned;
	}

	/**
	 * Override of onBeforeCompile, called internally
	 *
	 * @param shader the shader
	 * @param renderer the renderer
	 */
	override onBeforeCompile(shader: Shader, renderer: WebGLRenderer): void {
		super.onBeforeCompile(shader, renderer);
		this.#setUniforms(shader);
	}

	/**
	 * Function called before the rendering that updates some uniforms
	 *
	 * @param node The index of the node that should be rendered
	 * @param level The depth level of the node in the tree
	 */
	updateNode(node: number, level: number): void {
		const offset = this.#visibleNodeTextureOffsets.get(node);
		this.uniforms.uOffset.value = offset;
		this.uniforms.uLevel.value = level;
		this.uniformsNeedUpdate = true;
	}

	/**
	 * Update the material by computing the uniforms
	 *
	 * @param camera The current camera
	 * @param size The size of the canvas (in pixels)
	 * @param spacing The spacing of the tree
	 * @param box The bounding box of the root node
	 * @param nodes The list of current visible nodes
	 * @param pixelRatio The pixel ratio of the current canvas
	 */
	update(camera: Camera, size: Vector2, spacing: number, box: Box3, nodes: LodTreeNode[], pixelRatio: number): void {
		this.updateUniforms(camera, size, spacing, box, pixelRatio);
		this.computeTextures(box, nodes);
	}

	/**
	 * sets the uniforms of the material and of the passed in shader if any
	 *
	 * @param shader The shader that is about to be rendered and needs it's uniforms set
	 */
	#setUniforms(shader?: Shader): void {
		const { minSize, maxSize } = this.parameters;
		this.uniforms = UniformsUtils.merge([
			ShaderLib.points.uniforms,
			{
				uSlope: makeUniform(0),
				uOctreeSpacing: makeUniform(0),
				uIsOrthographic: makeUniform(false),
				uViewportSize: makeUniform(new Vector2(0, 0)),
				uOrthoFactor: makeUniform(0),
				uTreeStructureTex: makeUniform(this.#treeTexture),
				uBoxTex: makeUniform(this.#boxesTexture),
				uOffset: makeUniform(0),
				uBoxMin: makeUniform(new Vector3()),
				uBoxMax: makeUniform(new Vector3()),
				uLevel: makeUniform(0),
				uDebug: makeUniform(false),
				uMinSize: makeUniform(minSize),
				uMaxSize: makeUniform(maxSize),
				uDpr: makeUniform(1),
			},
		]);
		if (shader) {
			shader.uniforms = this.uniforms;
		}
	}

	/**
	 * Update the uniforms that depend on the camera and the tree metadata
	 *
	 * @param camera The current camera
	 * @param size The size of the canvas (in pixels)
	 * @param spacing The spacing of the tree
	 * @param box The bounding box of the root node
	 * @param pixelRatio The pixel ratio of the current canvas
	 */
	private updateUniforms(camera: Camera, size: Vector2, spacing: number, box: Box3, pixelRatio: number): void {
		if (camera instanceof PerspectiveCamera) {
			this.uniforms.uSlope.value = Math.tan(((camera.fov * Math.PI) / 180) * 0.5);
			this.uniforms.uIsOrthographic.value = false;
		} else if (camera instanceof OrthographicCamera) {
			this.uniforms.uOrthoFactor.value = size.x / (camera.right - camera.left);
			this.uniforms.uIsOrthographic.value = true;
		}
		this.uniforms.uOctreeSpacing.value = spacing;
		this.uniforms.uViewportSize.value.copy(size);
		this.uniforms.uBoxMin.value.copy(box.min);
		this.uniforms.uBoxMax.value.copy(box.max);
		this.uniforms.uDpr.value = pixelRatio;
		this.uniformsNeedUpdate = true;
	}

	/**
	 * Compute the textures used by this material
	 *
	 * @param box The bounding box of the root node
	 * @param nodes The list of current visible nodes
	 */
	private computeTextures(box: Box3, nodes: LodTreeNode[]): void {
		const sortedNodes = nodes.slice(0, Math.min(TEXTURE_SIZE, nodes.length)).sort((a, b) => {
			if (a.parentId === undefined || b.parentId === undefined) return a.depth - b.depth;
			if (a.depth === b.depth) return a.parentId - b.parentId;
			return a.depth - b.depth;
		});

		const DENSITY_OFFSET = 1.5;

		const treeData = new Uint8Array(sortedNodes.length * 4);
		const boxesData = new Float32Array(sortedNodes.length * 4);
		this.#visibleNodeTextureOffsets = new Map();

		const invBoxSize = box.getSize(this.#tempMember.invBoxSize);
		invBoxSize.x = 1 / invBoxSize.x;
		invBoxSize.y = 1 / invBoxSize.y;
		invBoxSize.z = 1 / invBoxSize.z;
		const offsetsToChild = new Array(sortedNodes.length).fill(Infinity);
		for (let i = 0; i < sortedNodes.length; i++) {
			const node = sortedNodes[i];

			this.#visibleNodeTextureOffsets.set(node.id, i);

			const boxMinNormalized = this.#tempMember.boxMinimum
				.copy(node.boundingBox.min)
				.sub(box.min)
				.multiply(invBoxSize);
			const boxMaxNormalized = this.#tempMember.boxMaximum
				.copy(node.boundingBox.max)
				.sub(box.min)
				.multiply(invBoxSize);

			const boxIndex = i * 4;
			boxesData[boxIndex + 0] = (boxMinNormalized.x + boxMaxNormalized.x) * 0.5;
			boxesData[boxIndex + 1] = (boxMinNormalized.y + boxMaxNormalized.y) * 0.5;
			boxesData[boxIndex + 2] = (boxMinNormalized.z + boxMaxNormalized.z) * 0.5;
			boxesData[boxIndex + 3] = (boxMaxNormalized.x - boxMinNormalized.x) * 0.5;

			if (i > 0) {
				const { parentId } = node;
				if (parentId === undefined) continue;
				const parentOffset = this.#visibleNodeTextureOffsets.get(parentId);

				// Parent node is not yet loaded in GPU
				if (parentOffset === undefined) continue;

				const parentOffsetToChild = i - parentOffset;

				offsetsToChild[parentOffset] = Math.min(offsetsToChild[parentOffset], parentOffsetToChild);

				treeData[parentOffset * 4 + 0]++;
				treeData[parentOffset * 4 + 1] = offsetsToChild[parentOffset] >> 8;
				treeData[parentOffset * 4 + 2] = offsetsToChild[parentOffset] % 256;
			}

			const density = node.pointDensity;
			if (density > 0) {
				const lodOffset = Math.log2(density) / 2 - DENSITY_OFFSET;
				const offsetUint8 = (lodOffset + 10) * 10;
				treeData[i * 4 + 3] = offsetUint8;
			} else {
				treeData[i * 4 + 3] = 100;
			}
		}

		this.uniforms.uTreeStructureTex.value = this.#treeTexture;
		this.#treeTexture.image.data.set(treeData);
		this.#treeTexture.needsUpdate = true;

		this.uniforms.uBoxTex.value = this.#boxesTexture;
		this.#boxesTexture.image.data.set(boxesData);
		this.#boxesTexture.needsUpdate = true;

		this.uniformsNeedUpdate = true;
	}
}

/**
 * The (known) error codes for failing background tasks coming from the CAD importer.
 *
 * See
 * - https://faro01.atlassian.net/wiki/spaces/HOLO/pages/3539435793/Error+Codes+Overview
 * - https://faro01.atlassian.net/wiki/spaces/BUIL/pages/3612312744/Technical+specs+for+the+CAD+to+mesh+worker.
 */
export enum CadImporterErrorCode {
  /**
   * Unexpected error.
   * Would typically require debugging.
   */
  cadImporterUnknownError = "CADImporter_UnknownError",

  /**
   * Invalid file format.
   * Cannot be converted because we do not support this format.
   */
  cadImporterBadFormat = "CADImporter_BadFormat",

  /**
   * Autodesk services are (temporarily) down, preventing the conversion now.
   * The end-user should typically re-attempt the conversion later.
   */
  cadImporterAutodeskDown = "CADImporter_AutodeskDown",

  /**
   * We failed to convert the model due to timeout on Autodesk side.
   * Might be due to the model being too large, or Autodesk services being temporary overloaded.
   */
  cadImporterAutodeskTimeout = "CADImporter_AutodeskTimeout",

  /**
   * Conversion failed.
   * The model is either corrupted/invalid, or its contents is not supported by us.
   */
  cadImporterCannotConvert = "CADImporter_CannotConvert",

  /**
   * The model contains no 3D geometry,
   * or we have been unable to extract a 3D mesh from it (e.g. the model contains 2D draft only).
   */
  cadImporterNoGeometry = "CADImporter_NoGeometry",

  /**
   * Unexpected error during the SVF to GLB step (should only happen during testing). This is typically happening when
   * the model is so big that it is not possible to get one single GLB for the whole model.
   */
  cadImporterSvfToGlbFailure = "CADImporter_SvfToGlbFailure",

  /**
   * Failed to process a *.glb model uploaded by the end-user (should only happen during testing)
   */
  cadImporterFailedToProcessUserGlb = "CADImporter_FailedToProcessUserGlb",

  /**
   * Internal error. Failed to save metadata/model tree.
   */
  cadImporterFailedSavingMetadata = "CADImporter_FailedSavingMetadata",

  /**
   * The Autodesk APS credentials are invalid (should only happen during testing).
   */
  cadImporterBadAPSCredentials = "CADImporter_BadAPSCredentials",

  /**
   * Internal error. Failed to find CAD node from HB server to download model file.
   */
  cadImporterNoCadNodeToDownload = "CADImporter_NoCadNodeToDownload",

  /**
   * Internal error. Failed to prepare the stream of the cad model file.
   */
  cadImporterFailedToGetCadFile = "CADImporter_FailedToGetCadFile",

  /**
   * Internal error. Failed to upload data to HB server.
   */
  cadImporterFailedToUploadData = "CADImporter_FailedToUploadData",

  /**
   * Failure in uploading cad model to Autodesk APS service.
   */
  cadImporterFailedToUploadToAPS = "CADImporter_FailedToUploadToAPS",

  /**
   * There Unexpected errors returned from Autodesk APS service.
   */
  cadImporterAPSUnknownError = "CADImporter_APSUnknownError",

  /**
   * Failed to convert SVF to GLTF.
   */
  cadImporterFailedSvfToGltf = "CADImporter_FailedSvfToGltf",

  /**
   * Failed to convert GLTF to GLB.
   */
  cadImporterFailedGltfToGlb = "CADImporter_FailedGltfToGlb",

  /**
   * Failed to convert the main GLB, although the submeshes succeeded.
   * This error is as critical as cadImporterSvfToGlbFailure for now,
   * but will not be an error anymore when https://faro01.atlassian.net/browse/CADBIM-220 is completed.
   */
  cadImporterPartialSuccessNoMainGlb = "CADImporter_PartialSuccessNoMainGlb",

  /**
   * Failed to convert submeshes but succeed with main GLB
   * Now not an issue from user point of view,
   * it will become one when https://faro01.atlassian.net/browse/CADBIM-220 is completed.
   */
  cadImporterPartialSuccessNoSubMeshes = "CADImporter_PartialSuccessNoSubMeshes",
}

/**
 * @returns Wether or not the provided string is a valid CAD importer error code
 * @param errorCode String to check
 */
export function isCadImporterErrorCode(
  errorCode?: string,
): errorCode is CadImporterErrorCode {
  return (
    !!errorCode &&
    Object.values<string>(CadImporterErrorCode).includes(errorCode)
  );
}

// TODO: Allow user to contact support (https://faro01.atlassian.net/browse/SWEB-2418)
/**
 * Convert an error code to a message for the user.
 *
 * @param taskName Name of the task, which will be rendered for some messages.
 * @param errorCode The error code to generate an error message for.
 *   If not given, a generic message will be shown.
 * @returns A message explaining the error to the user.
 */
export function cadImporterTaskErrorToUserMessage(
  taskName: string,
  errorCode?: CadImporterErrorCode,
): string {
  switch (errorCode) {
    default:
    case undefined:
    case CadImporterErrorCode.cadImporterUnknownError:
    case CadImporterErrorCode.cadImporterSvfToGlbFailure:
    case CadImporterErrorCode.cadImporterFailedToProcessUserGlb:
      return "Unexpected error. Please contact support.";
    case CadImporterErrorCode.cadImporterFailedSavingMetadata:
    case CadImporterErrorCode.cadImporterNoCadNodeToDownload:
    case CadImporterErrorCode.cadImporterFailedToGetCadFile:
    case CadImporterErrorCode.cadImporterFailedToUploadData:
      return "Internal error occurred. Please try again later. If problem persists, contact support, or try uploading a smaller file.";
    case CadImporterErrorCode.cadImporterBadAPSCredentials:
      return "Conversion service temporarily down. Please try again later. If this problem persist, please contact support.";
    case CadImporterErrorCode.cadImporterBadFormat:
      return "3D Model file format is not supported by the conversion service.";
    case CadImporterErrorCode.cadImporterAutodeskDown:
      return "Conversion service temporarily down. Try later. If this problem persist, please contact support.";
    case CadImporterErrorCode.cadImporterAutodeskTimeout:
      return "Conversion service timed out. Please try again later. If problem persists, try uploading a smaller file.";
    case CadImporterErrorCode.cadImporterCannotConvert:
      return "Cannot convert this model. It might be corrupted or invalid.";
    case CadImporterErrorCode.cadImporterNoGeometry:
      // was  previously "This model contains no geometry to be displayed."
      return "No geometry file was generated by the conversion service. Model contains no geometry.";
    case CadImporterErrorCode.cadImporterFailedToUploadToAPS:
      return "Upload to conversion service failed. Please try again later. If problem persists, try uploading a smaller file.";
    case CadImporterErrorCode.cadImporterAPSUnknownError:
      return "Unexpected error returned by the conversion service.";
    case CadImporterErrorCode.cadImporterFailedSvfToGltf:
      return "Failed to process the conversion service output. The model might be too large. Please try simplifying the model or uploading a subset of the model.";
    case CadImporterErrorCode.cadImporterFailedGltfToGlb:
      return "Failed to convert the model to a file that can be displayed by Sphere XG. The uploaded model might be too large. Please try simplifying the model or uploading a subset of the model.";
    case CadImporterErrorCode.cadImporterPartialSuccessNoMainGlb:
      return "Failed to process the conversion service output. The model might be too large. Please try simplifying the model or uploading a subset of the model.";
    case CadImporterErrorCode.cadImporterPartialSuccessNoSubMeshes:
      // This error is as critical as cadImporterSvfToGlbFailure for now,
      // but will soon be considered as not an error anymore
      // when https://faro01.atlassian.net/browse/CADBIM-220 is completed.
      return "Unexpected error. Please contact support.";
  }
}

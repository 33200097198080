import { Box, SxProps, Theme, Tooltip } from "@mui/material";
import { useState } from "react";
import { FaroDialog } from "../dialog/faro-dialog";
import { SphereXGTextIcon } from "../icons/icons";
import { FaroText } from "../text/faro-text/faro-text";

/** Current year */
const YEAR = new Date().getFullYear();

type AboutDialogProps = {
  /** App version to display in the dialog */
  appVersion: string;

  /** Last time the page was updated */
  lastPageUpdate?: Date;

  /** Optional component to be displayed in about dialog */
  additionalAboutInfo?: JSX.Element;

  /** Callback function on open about dialog */
  onOpen?(): void;

  /** Custom style to apply to the dialog */
  sx?: SxProps<Theme>;

  /** Custom style to apply to the text */
  textSx?: SxProps<Theme>;
};

/**
 * @returns a simple link that says "About" and when clicked it opens a dialog to give more details about the application,
 * like the version and the copyright.
 */
export function AboutDialog({
  appVersion,
  lastPageUpdate,
  additionalAboutInfo,
  onOpen,
  sx,
  textSx,
}: AboutDialogProps): JSX.Element {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  function openDialog(): void {
    onOpen?.();
    setIsDialogOpen(true);
  }

  return (
    <>
      <FaroText variant="bodyS" sx={textSx} onClick={openDialog} title="About">
        About
      </FaroText>
      <FaroDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        showXButton
        title={
          <Box
            component="div"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box component="div">
              <SphereXGTextIcon
                sx={{
                  height: "60px",
                  width: "125px",
                }}
              />
            </Box>
          </Box>
        }
      >
        <Box
          component="div"
          sx={{
            minWidth: "400px",
            ...sx,
          }}
        >
          <Box component="div">
            <Box
              component="div"
              sx={{
                fontSize: "14px",
                color: "gray850",
                marginBottom: "12px",
              }}
            >
              <FaroText variant="bodyM"> Version &nbsp;</FaroText>
              <Tooltip
                // Don't show tooltip if lastPageUpdate is not defined
                title={
                  lastPageUpdate ? (
                    <span>Updated: {lastPageUpdate.toString()}</span>
                  ) : null
                }
                enterDelay={0}
                sx={{
                  textDecoration: "none",
                  // Disable effect that it can be hovered if there's no last update date.
                  color: lastPageUpdate ? "blue500" : "gray850",
                  fontWeight: "600",
                  letterSpacing: "1px",
                }}
              >
                {/** Use span to keep it on the same line */}
                <Box component="span" sx={{ boxShadow: "none" }}>
                  <FaroText variant="buttonM">{appVersion}</FaroText>
                </Box>
              </Tooltip>
            </Box>

            {additionalAboutInfo && (
              <Box component="div" sx={{ mb: 2.5 }}>
                {additionalAboutInfo}
              </Box>
            )}

            <FaroText variant="bodyM">
              Copyright © {YEAR} FARO. All Rights Reserved
            </FaroText>
          </Box>
        </Box>
      </FaroDialog>
    </>
  );
}

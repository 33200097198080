import { Theme } from "@emotion/react";
import { Box, Divider, SxProps } from "@mui/material";
import { CSSProperties, Fragment, PropsWithChildren, useMemo } from "react";
import { blue, neutral } from "../colors";
import { FaroText } from "../text/faro-text/faro-text";
import { AboutDialog } from "./about-dialog";
import { Link } from "./user-menu-types";

export type UserMenuBodyProps = {
  /** Links to display at the bottom of the menu */
  links?: Link[];

  /** Current version of the app. It will be displayed in the about dialog */
  appVersion?: string;

  /**
   * Date of the last time the page was updated. It will be displayed in a tooltip in the about dialog.
   * It will only be present if appVersion is defined
   */
  lastPageUpdate?: Date;

  /** Optional component to be displayed in about dialog */
  additionalAboutInfo?: JSX.Element;

  /** Callback function on open about dialog */
  onOpenAbout?(): void;

  /** Optional style to apply to the links at the bottom of the user menu */
  linkStyle?: SxProps<Theme>;
};

const linkHoverStyle = {
  ":hover": { color: blue[500], cursor: "pointer" },
};

/**
 * @returns a list of menu options separated by a divider and a list of links properly styled.
 */
export function UserMenuBody({
  links,
  linkStyle,
  appVersion,
  lastPageUpdate,
  additionalAboutInfo,
  onOpenAbout,
  children,
}: PropsWithChildren<UserMenuBodyProps>): JSX.Element {
  /** Width of each link in the list, it depends on how many links are passed */
  const linkWidth: CSSProperties["width"] = useMemo(
    () =>
      (!!links && links.length > 0) || appVersion
        ? `${100 / ((links?.length ?? 0) + (appVersion ? 1 : 0))}%`
        : "100%",
    [appVersion, links],
  );

  return (
    <Box component="div" padding="8px" paddingTop="0">
      <SphereDivider />

      <Box
        component="div"
        sx={{
          ".MuiMenuItem-root:hover": {
            color: "primary.main",
            ".MuiListItemIcon-root>*": {
              color: "primary.main",
            },
            ".MuiTypography-root ": {
              color: "primary.main",
            },
          },
        }}
      >
        {/** Display the menu options with a divider between them */}
        {Array.isArray(children) ? (
          children.map((child, index) => (
            <Fragment key={index}>
              {child}
              <SphereDivider />
            </Fragment>
          ))
        ) : (
          <>
            {children}
            {/** Add a divider only if there is a menu option */}
            {children && <SphereDivider />}
          </>
        )}
      </Box>

      {/* Bottom section for links to About, ToS, Privacy, etc. */}
      <Box
        component="div"
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
        width="100%"
        height="32px"
        paddingX="4px"
        paddingY="4px"
        sx={{
          "& .MuiListItemText-root": {
            "& .MuiTypography-root": {
              color: "gray",
              fontSize: "14px",
            },
          },
          "& .MuiMenuItem-root": {
            "&. MuiButtonBase-root:active": {
              backgroundColor: "gray",
            },
          },
        }}
      >
        {appVersion && (
          <AboutDialog
            appVersion={appVersion}
            lastPageUpdate={lastPageUpdate}
            additionalAboutInfo={additionalAboutInfo}
            onOpen={onOpenAbout}
            textSx={{
              ...linkHoverStyle,
              ...linkStyle,
              width: linkWidth,
              textAlign: "center",
              px: "5px",
            }}
          />
        )}
        {/** Add divider after the About link if other links are present */}
        {appVersion && links && links.length > 0 && <LinkDivider />}
        {links?.map((link, index) => (
          <Fragment key={`menu-link-${index}`}>
            <Box
              component="div"
              width={linkWidth}
              textAlign="center"
              sx={{
                fontSize: "11px",
                paddingX: "5px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {link.href && (
                <FaroText
                  variant="bodyS"
                  sx={{ ...linkHoverStyle, ...linkStyle }}
                  title={link.title ?? link.text}
                  onClick={() => {
                    link.callback?.();

                    window.open(link.href, "_blank");
                  }}
                  // To be more secure, links are set to noreferrer which means that the target page
                  // will not know from where the visitor came from and additionally the target page will not be
                  // able to take control of the original tab with the window.opener property
                  rel="noreferrer"
                >
                  {link.text}
                </FaroText>
              )}
            </Box>
            {/* Show a separation dot between elements on their right (apart from last element)*/}
            {index !== links.length - 1 && <LinkDivider />}
          </Fragment>
        ))}
      </Box>
    </Box>
  );
}

type SphereDividerProps = {
  /** Optional style to apply to the divider */
  sx?: SxProps<Theme>;
};

/**
 * @returns a gray line used to separate menu items
 */
function SphereDivider({ sx }: SphereDividerProps): JSX.Element {
  return (
    <Divider
      sx={{
        my: "8px",
        borderColor: neutral[100],
        ...sx,
      }}
    />
  );
}

/**
 * @returns a small dot used to separate links in the menu
 */
function LinkDivider(): JSX.Element {
  return <Box component="div">&#xb7;</Box>;
}

uniform float size;
uniform float scale;
uniform float minDeviation;
uniform float maxDeviation;
uniform vec4 referencePlane;

varying vec3 vColor;

#if NUMBER_OF_COLOR_KEYS > 1
    uniform vec4 colorKeys[NUMBER_OF_COLOR_KEYS];
#endif

#include <common>

float distancePointToPlane(vec3 point, vec4 plane) {
    return dot(plane.xyz, point) + plane.w;
}

void main() {
    #include <begin_vertex>
    #include <project_vertex>

    gl_PointSize = size;
    #ifdef USE_SIZEATTENUATION
        bool isPerspective = isPerspectiveMatrix( projectionMatrix );
        if ( isPerspective ) gl_PointSize *= ( scale / - mvPosition.z );
    #endif

    vColor = vec3(0.0, 0.0, 0.0);
    #if NUMBER_OF_COLOR_KEYS > 1
        float deviation = distancePointToPlane(position, referencePlane);
        if (deviation <= minDeviation) {
            vColor = colorKeys[0].rgb;
        } else if (deviation >= maxDeviation) {
            vColor = colorKeys[NUMBER_OF_COLOR_KEYS - 1].rgb;
        } else {
            float ratio = (deviation - minDeviation) / (maxDeviation - minDeviation);
            for (int i = 0; i < NUMBER_OF_COLOR_KEYS - 1; i++) {
                if (ratio <= colorKeys[i+1].a) {
                    float factor = (ratio - colorKeys[i].a) / (colorKeys[i + 1].a - colorKeys[i].a);
                    vColor = mix(colorKeys[i].rgb, colorKeys[i + 1].rgb, factor);
                    break;
                }
            }
        }
    #endif
    #include <clipping_planes_vertex>
}
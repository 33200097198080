/**
 * Error to notify that an async task has been cancelled by an AbortController
 */
export class AbortError extends Error {
  /**
   * @param taskAborted The name of the aborted task
   */
  constructor(taskAborted: string) {
    super(`Task ${taskAborted} aborted`);
  }
}

/**
 * Error to notify that a fetch network connection was successful (the host exists) but the requested resource does not exists
 */
export class FetchError extends Error {
  /**
   * @param host The host we queried
   * @param missingResource The missing resource
   */
  constructor(host: string, missingResource: string) {
    super(`The resource ${missingResource} was not found on ${host}`);
  }
}

/**
 * Error to signal that a parsing of some xml/json failed
 */
export class ParsingError extends Error {
  /**
   * @param what A description of the file or resources that we failed to parse
   */
  constructor(what: string) {
    super(`Parsing of ${what} failed`);
  }
}

/**
 * Error to signal that some configuration had an invalid or incompatible value
 */
export class InvalidConfigurationError extends Error {
  /**
   * @param what a description of the invalid configuration
   */
  constructor(what: string) {
    super(what);
  }
}

/**
 * Error to signal that we're accessing an array out of the valid range
 */
export class RangeError extends Error {
  /**
   * @param what The error description
   */
  constructor(what: string) {
    super(what);
  }
}

/**
 * Check if an error received by LotV code or by the browser is a download abort
 *
 * @param error The error or string received
 * @returns true if that was an abort
 */
export function isDownloadAbortError(error: unknown): boolean {
  if (error instanceof AbortError) return true;
  if (typeof error === "string") {
    // Check error message for abort on chrome
    if (error === "The user aborted a request.") return true;

    // Check error message for abort on firefox
    if (error.includes("The operation was aborted.")) return true;

    // Check error message for abort  on iPad Pro
    if (error.includes("Fetch is aborted")) return true;
  }
  return false;
}

/**
 * @param error to check
 * @returns true if it's a network connection error from the fetch api
 */
export function isNetworkConnectionError(error: unknown): boolean {
  if (!(error instanceof Error)) {
    return false;
  }

  // Check error message for fetch api network errors on chrome
  if (error.message === "Failed to fetch") {
    return true;
  }

  // Check error message for fetch api network errors on firefox
  if (error.message === "NetworkError when attempting to fetch resource.") {
    return true;
  }

  // Check error message for fetch api network errors on safari
  if (error.message === "Load failed") {
    return true;
  }

  return false;
}

import { clamp } from "lodash";
import { RefObject, useCallback, useEffect, useRef, useState } from "react";

export type UseHorizontalResizeParams = {
  /** The side of the container the handle is on @default right */
  handleSide?: "right" | "left";

  /** Initial width */
  initialWidth: number;

  /** Minimum width to maintain */
  minWidth: number;

  /** Maximum width to maintain (not limited by default) */
  maxWidth?: number;
};

type UseResizeReturn = {
  /** Width to use for the element to resize */
  width: number;

  /** Indicator if resizing mode is active */
  isResizing: boolean;

  /** Ref to the handler */
  handleRef: RefObject<HTMLElement>;
};

/**
 * Hook that allows to adjust the width of an element via dragging of the mouse
 *
 * @returns {UseResizeReturn} the provided enableResize method should be attached to the onMouseDown handler of the element
 * that can be dragged by the user
 */
export function useHorizontalResize({
  handleSide = "right",
  initialWidth,
  minWidth,
  maxWidth = Number.MAX_SAFE_INTEGER,
}: UseHorizontalResizeParams): UseResizeReturn {
  const [isResizing, setIsResizing] = useState(false);
  const [width, setWidth] = useState(initialWidth);

  const [widthAtResizeStart, setWidthAtResizeStart] = useState(initialWidth);
  const [clientXAtResizeStart, setClientXAtResizeStart] = useState(0);

  const handleRef = useRef<HTMLElement>(null);

  const startResizing = useCallback(
    (e: PointerEvent) => {
      e.stopPropagation();

      if (!handleRef.current) return;
      handleRef.current.setPointerCapture(e.pointerId);
      setWidthAtResizeStart(width);
      setClientXAtResizeStart(e.clientX);
      setIsResizing(true);
    },
    [width],
  );

  const stopResizing = useCallback((e: PointerEvent) => {
    e.stopPropagation();

    if (!handleRef.current) return;
    handleRef.current.releasePointerCapture(e.pointerId);
    setIsResizing(false);
  }, []);

  const resize = useCallback(
    (e: PointerEvent) => {
      if (handleRef.current && isResizing) {
        e.stopPropagation();

        const directionFactor = handleSide === "right" ? 1 : -1;

        const newWidth =
          widthAtResizeStart +
          (e.clientX - clientXAtResizeStart) * directionFactor;

        setWidth(clamp(newWidth, minWidth, maxWidth));
      }
    },
    [
      isResizing,
      handleSide,
      widthAtResizeStart,
      clientXAtResizeStart,
      minWidth,
      maxWidth,
    ],
  );

  // Setup necessary event listeners for mouse actions, and remove them on unmount
  useEffect(() => {
    if (!handleRef.current) return;
    const handle = handleRef.current;
    handle.addEventListener("pointerdown", startResizing);
    handle.addEventListener("pointermove", resize);
    handle.addEventListener("pointerup", stopResizing);

    return () => {
      handle.removeEventListener("pointerdown", startResizing);
      handle.removeEventListener("pointermove", resize);
      handle.removeEventListener("pointerup", stopResizing);
    };
  }, [stopResizing, resize, startResizing]);

  return { width, isResizing, handleRef };
}

varying vec3 vColor;

#include <common>
#include <clipping_planes_pars_fragment>

void main() {

	#include <clipping_planes_fragment>

	gl_FragColor = vec4( vColor,1 );
}
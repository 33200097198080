import { Box, Slider, SliderProps } from "@mui/material";
import { CSSProperties } from "react";
import { blue, cyan, neutral } from "../colors";

export type FaroSliderProps = SliderProps & {
  /**
   * True to use the dark variant of the slider.
   *
   * @default false
   */
  dark?: boolean;
};

type SliderVariantStyle = {
  /** Color used for thumb, track and marks in the track of the slider */
  sliderColor: CSSProperties["color"];
  /** Color used part of the track that is not filled */
  secondarySliderColor: CSSProperties["color"];
  /** Color used for the marks' labels */
  labelColor: CSSProperties["color"];
};

const DEFAULT_SLIDER_VARIANT: SliderVariantStyle = {
  sliderColor: blue[500],
  secondarySliderColor: blue[200],
  labelColor: neutral[500],
};

const DARK_SLIDER_VARIANT: SliderVariantStyle = {
  sliderColor: cyan[400],
  secondarySliderColor: blue[200],
  labelColor: neutral[300],
};

/**
 * @returns a slider component
 */
export function FaroSlider({
  dark = false,
  sx,
  ...rest
}: FaroSliderProps): JSX.Element {
  const style = dark ? DARK_SLIDER_VARIANT : DEFAULT_SLIDER_VARIANT;

  return (
    // The box is present to avoid the slider from overflowing when the the max value is set https://github.com/mui/material-ui/issues/13455
    <Box
      component="div"
      sx={{
        px: 0.65,
      }}
    >
      <Slider
        sx={{
          "& .MuiSlider-thumb": {
            color: style.sliderColor,
          },
          "& .MuiSlider-track": {
            color: style.sliderColor,
          },
          "& .MuiSlider-rail": {
            color: style.secondarySliderColor,
          },
          "& .MuiSlider-mark": {
            color: style.sliderColor,
            width: "4px",
            height: "4px",
            borderRadius: "50%",
          },
          "& .MuiSlider-markLabel": {
            color: style.labelColor,
          },
          "& .MuiSlider-valueLabel": {
            backgroundColor: neutral[950],
          },
          ...sx,
        }}
        {...rest}
      />
    </Box>
  );
}

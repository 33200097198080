import {
  validateNotNullishObject,
  validateOfExpectedValue,
  validatePrimitive,
} from "@faro-lotv/foundation";

/** ULM window message to redirect to the passed URL */
export type UlmRedirectMessage = {
  /** Message identifier */
  identifier: "UlmRedirectMessage";
  /** Redirect URL */
  redirectUrl: string;
};

/**
 * @param data the window message object to validate
 * @returns true if the data is a valid UlmRedirectMessage
 */
export function isUlmRedirectMessage(
  data: unknown,
): data is UlmRedirectMessage {
  return (
    validateNotNullishObject<UlmRedirectMessage>(data, "UlmRedirectMessage") &&
    validatePrimitive(data, "identifier", "string") &&
    validateOfExpectedValue(data, "identifier", "UlmRedirectMessage") &&
    validatePrimitive(data, "redirectUrl", "string")
  );
}

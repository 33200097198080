import { PointsMaterial, UniformsLib, UniformsUtils, Vector4 } from "three";
import frag from "../Shaders/ColormapPoints.frag";
import vert from "../Shaders/ColormapPoints.vert";
import { makeUniform } from "./Uniforms";

/**
 * ColormapPoints material that render each point's color using its deviation
 * to the reference plane and a colormap.
 */
export class ColormapPointsMaterial extends PointsMaterial {
	vertexShader: string;
	fragmentShader: string;
	uniforms = UniformsUtils.merge([
		UniformsLib.points,
		{
			minDeviation: makeUniform<number>(-1.0),
			maxDeviation: makeUniform<number>(1.0),
			referencePlane: makeUniform<Vector4>(new Vector4(1, 0, 0, 0)),
			colorKeys: makeUniform(new Array<Vector4>()),
		},
	]);

	defines = {
		NUMBER_OF_COLOR_KEYS: 0,
	};

	constructor() {
		super();
		this.vertexShader = vert;
		this.fragmentShader = frag;
		this.type = "ColormapPointsMaterial";

		this.size = 0.05;
		this.sizeAttenuation = true;
	}
}

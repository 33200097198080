import { ReactNode } from "react";

type FaroVarProps = JSX.IntrinsicElements["var"] &
  Record<string, boolean | ReactNode>;

/**
 * Component allowing to wrap content in the var HTML element and setting the variable name of the content.
 * This is used for telling localize the variable name to show in the localize dashboard.
 *
 * Example usage:
 * `Hello <FaroVar userName>{name}</FaroVar>, you are <FaroVar age>{ageInYears}</FaroVar> old`
 *
 * See https://developers.localizejs.com/docs/using-html-syntax-to-prepare-phrases#variables
 *
 * @returns children wrapped in the var HTML element
 */
export function FaroVar({ children, ...varProps }: FaroVarProps): JSX.Element {
  return <var {...varProps}>{children}</var>;
}

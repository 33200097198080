/**
 * Enum representing the available language codes.
 */
export enum LanguageCodes {
  English = "en",
  Spanish = "es",
  French = "fr",
  German = "de",
  Japanese = "ja",
}

/** CSS class to be used in order to disable translations for parts of the UI */
export const NO_TRANSLATE_CLASS = "notranslate";

import { assert } from "@faro-lotv/foundation";
import { Box3, Vector3 } from "three";
import { LodPointCloud, LodTreeNode } from ".";

/** Options to control the traversal of the LodTree */
export type LodTreeTraversalOptions = {
	/**
	 * Callback function used to control depth of the travese depth of the LodTree according to
	 * the current node box.
	 *
	 * @param box The bounding box of the current LodTree node in the local coordinate system of the pointcloud
	 * @returns True to keep traverse next level, otherwise not
	 */
	checkNodeBox(box: Box3): boolean;

	/**
	 * Callback function used to process each point in the node. Return true to continue processing
	 *
	 * @param position The position of the point in the local coordinate system of the pointcloud
	 * @returns True means client has process the point, false means client skip the point
	 */
	processNodePoint(position: Vector3): boolean;

	/** The maximum number of points the client want to process */
	maxNumberOfPoints: number;

	/**
	 * The minimal the node point density, defined by `LodTreeNode.pointDensity` which is
	 * the distance in millimeters between two consecutive points
	 */
	minPointDensity: number;
};

/**
 * Traverse the LodTree and process each point in the node.
 * Only the node points in memory will be processed.
 *
 * @param pointCloud The LodTree point cloud to traverse
 * @param options The options to control the traversal
 */
export function traverseLodTree(pointCloud: LodPointCloud, options: LodTreeTraversalOptions): void {
	const lodTree = pointCloud.tree;
	assert(options.maxNumberOfPoints > 0 && options.minPointDensity > 0, "Invalid PotreeTraversalOptions");

	const queue: LodTreeNode[] = [lodTree.root];

	const tmpPt = new Vector3();
	let totalPoints = 0;
	while (queue.length > 0 && totalPoints < options.maxNumberOfPoints) {
		// Potree nodes at same depth contain points of simlar density.
		// Breath first to ensure all points at the same depth are processed first.
		const curNode = queue.shift();
		if (!curNode) {
			continue;
		}
		if (curNode.pointDensity !== 0 && curNode.pointDensity < options.minPointDensity) {
			// Skipping node because of pointDensity
			continue;
		}
		if (!options.checkNodeBox(curNode.boundingBox)) {
			// Skipping node by checkNodeBox
			continue;
		}
		for (const child of curNode.children) {
			queue.push(child);
		}
		let positionData: Float32Array | undefined = undefined;
		const nodePoints = pointCloud.nodesInMemory.get(curNode.id)?.points;
		if (nodePoints) {
			positionData = nodePoints.positionArray;
			for (let i = 0; i < nodePoints.size; i++) {
				tmpPt.set(positionData[i * 3], positionData[i * 3 + 1], positionData[i * 3 + 2]);
				if (options.processNodePoint(tmpPt)) {
					totalPoints++;
					if (totalPoints >= options.maxNumberOfPoints) return;
				}
			}
		}
	}
}
